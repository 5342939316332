<template>
    <div class="login">
        <form class="login__form">
            <logo
                class="login__form-logo"
                :collapse="false"
            />
            <input-field
                label="Email"
                type="text"
                v-model="email"
                :errors="errors.email"
            />
            <input-field
                label="Password"
                type="password"
                v-model="password"
                :errors="errors.password"
            />
            <v-button
                @click.prevent="submitForm"
                :loading="loading"
            >
                Login
            </v-button>
        </form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import VButton from '../../components/Action/VButton.vue';
import InputField from '../../components/Form/Input.vue';
import Logo from '../../components/Presentation/Logo.vue';

export default {
    name: 'Login',
    components: { Logo, InputField, VButton },
    data: () => ({
        email: '',
        password: '',
        loading: false,
        errors: {
            email: [],
            password: [],
        },
    }),
    methods: {
        async submitForm() {
            this.loading = true;

            const { errors } = await this.login({
                email: this.email,
                password: this.password,
            });

            this.errors = errors;

            this.loading = false;
        },
        ...mapActions('auth', ['login']),
    },
};
</script>

<style lang="scss" scoped>
.login {
    display: flex;
    justify-content: center;
    height: 100vh;

    &__form-logo {
        font-size: 5rem;
        text-align: center;
        margin-bottom: 5rem;
    }

    &__form {
        width: 40rem;
        margin-top: 20rem;
    }
}
</style>
